import { ClientService, RequestMethod } from "../client-service";
import { StoreManager } from "@/storage/store-manager";
import { RequestResponseLogic } from "../Ui/request-response";
import { UserLogic } from "./user";

import moment from "moment";
import Vue from "vue";

const USER_LOGIN_PATH = "/auth/login";
const EESZT_INIT = "/auth/eeszt/eszig/init";
const EESZT_LOGIN = "/auth/eeszt/eszig/login";
const EESZT_LOGIN_HARD_TOKEN = "/auth/eeszt/otp/login";
const EESZT_LOGIN_MOBIL_TOKEN = "/auth/eeszt/motp/login";

const USER_TOKEN_STORAGE_KEY = process.env.VUE_APP_USER_TOKEN_STORAGE_KEY;
export const USER_EESZT_TOKEN_STORAGE_KEY =
  process.env.VUE_APP_EESZT_USER_TOKEN_STORAGE_KEY;

//var promise = null
export const AuthLogic = {
  // get user token
  async getTokenByUsernamePassword(username, password) {
    // starting request
    const result = await ClientService.sendRequestBasicAuth(
      RequestMethod.GET,
      USER_LOGIN_PATH,
      //TODO: validity átállítás, csak test céllal lett beállítva
      { validity: 10000000 },
      username,
      password
    );
    const getTokenResponse = await RequestResponseLogic.checkRequestResponse(
      result
    );
    if (!getTokenResponse.Code) {
      StoreManager.sessionStoragePut(USER_TOKEN_STORAGE_KEY, getTokenResponse);
      StoreManager.localStoragePut(USER_TOKEN_STORAGE_KEY, getTokenResponse);
    }
    return getTokenResponse;
  },

  async initEeszt(body, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      EESZT_INIT,
      params,
      body
    );
    Vue.prototype.$loggedUser.SelectedOrganization = body.OrganizationNickname;
    UserLogic.saveLoginUser(Vue.prototype.$loggedUser);
    return await RequestResponseLogic.checkRequestResponse(result);
  },

  async loginEeszt(body, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      EESZT_LOGIN,
      params,
      body
    );
    const response = await RequestResponseLogic.checkRequestResponse(result);
    if (!response.Code) {
      StoreManager.localStoragePut(USER_EESZT_TOKEN_STORAGE_KEY, response);
    }
    return response;
  },

  async loginEesztByHardToken(body, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      EESZT_LOGIN_HARD_TOKEN,
      params,
      body
    );
    const response = await RequestResponseLogic.checkRequestResponse(result);
    if (!response.Code) {
      StoreManager.sessionStoragePut(USER_EESZT_TOKEN_STORAGE_KEY, response);
      StoreManager.localStoragePut(USER_EESZT_TOKEN_STORAGE_KEY, response);
    }
    return response;
  },

  async loginEesztByMobilToken(body, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      EESZT_LOGIN_MOBIL_TOKEN,
      params,
      body
    );
    const response = await RequestResponseLogic.checkRequestResponse(result);
    if (!response.Code) {
      StoreManager.sessionStoragePut(USER_EESZT_TOKEN_STORAGE_KEY, response);
      StoreManager.localStoragePut(USER_EESZT_TOKEN_STORAGE_KEY, response);
    }
    return response;
  },

  canStartEesztRequest() {
    const localEesztToken = StoreManager.localStorageGet(
      USER_EESZT_TOKEN_STORAGE_KEY
    );
    var userToken;
    if (localEesztToken) {
      userToken = localEesztToken;
      StoreManager.sessionStoragePut(USER_EESZT_TOKEN_STORAGE_KEY, userToken);
      //localStorage.removeItem(USER_EESZT_TOKEN_STORAGE_KEY);
    } else {
      userToken = StoreManager.sessionStorageGet(USER_EESZT_TOKEN_STORAGE_KEY);
    }
    // 2) is it valid?
    if (
      userToken &&
      this.isTokenTimestampValid(userToken.ValidUntil, userToken.IssuedAt)
    ) {
      // yes, returning token
      return userToken;
    }
    //no, return null
    return null;
  },

  // returns the token, if the user can start requests, null if manual login is needed -- VENDEL
  canStartRequest() {
    // 1) get token from sessionStorage
    var userToken = StoreManager.sessionStorageGet(USER_TOKEN_STORAGE_KEY);
    if (!userToken) {
      userToken = StoreManager.localStorageGet(USER_TOKEN_STORAGE_KEY);
      StoreManager.sessionStoragePut(USER_TOKEN_STORAGE_KEY, userToken);
    }
    // 2) is it valid?
    if (
      userToken &&
      this.isTokenTimestampValid(userToken.ValidBy, userToken.IssuedAt)
    ) {
      // yes, returning token
      return userToken;
    }
    //no, return null
    return null;
  },

  isTokenTimestampValid(validBy, issuedAt) {
    // null checks
    if (!validBy || !issuedAt) {
      return false;
    }
    let tokenStart = moment(issuedAt);
    let tokenExpiry = moment(validBy);
    // now
    let now = moment();
    // validity check: if the start time is before and the expiry time is after the current time, the token is valid
    // we add a 10 sec delay on the expiry time to make sure the token will not expire by the time of the next request
    return (
      tokenStart.isBefore(now.add(10, "seconds")) &&
      tokenExpiry.isAfter(now.add(10, "seconds"))
    );
  },

  clearStorages() {
    localStorage.clear();
    sessionStorage.clear();
  },
};
