<!-- Version: 1.2.0 -->
<template>
  <b-container fluid>
    <b-row align-h="around" align-v="center">
      <div>
        <router-link to="Home">
          <button class="eh-header-button">
            {{ $t("healthProfessional.menu.home") }}
          </button>
        </router-link>
      </div>
      <div style="text-align: center">
        <!-- TODO: projekt enabledUiElements -->
        <span v-for="menu in menuElements" :key="menu.key">
          <router-link
            v-if="
              !enabledMenuElements ||
              (menu.uiElementName &&
                enabledMenuElements.includes(menu.uiElementName)) ||
              !menu.uiElementName
            "
            :to="{ path: menu.url }"
          >
            <button class="eh-header-view-button">
              {{ $t(menu.langKey) }}
            </button>
          </router-link>
        </span>
      </div>
      <div>
        <router-link to="/">
          <button @click="close" class="eh-header-button">
            {{ $t("base.basic.exit") }}
          </button>
        </router-link>
      </div>
    </b-row>
  </b-container>
</template>
<script>
import { AuthLogic } from "../../../Logic/Backend/auth";

export default {
  name: "MenuLine",
  props: {
    menuElements: Array,
    projectEnabledUiElements: Object,
    enabledMenuElements: Array,
  },
  methods: {
    close() {
      AuthLogic.clearStorages();
    },
  },
};
</script>
